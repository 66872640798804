import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import Seo from "../components/seo"
import marked from "marked"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import { GatsbyImage } from "gatsby-plugin-image"

class BlogTemplate extends React.Component {
  render() {
    const { post, site } = this.props.data
    const { previous, next } = this.props.pageContext
    // console.log(previous, next)

    return (
      <Layout>
        <Seo
          title={post.title}
          description={
            post.excerpt && post.excerpt.length > 156
              ? `${post.excerpt.substring(0, 154)}...`
              : post.excerpt || site.siteMetadata.description
          }
        />
        <div className="page-headline">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase text-white-50 flair">
                Blog
              </h6>
              <h1>
                <strong>{post.title}</strong>
                <br />
              </h1>
              <span className="text-white-50 small">
                <i className="fa fa-calendar-o pr-1" />
                {post.publishedAt}
              </span>
            </div>
          </div>
        </div>

        <div className="page-content">
          <Container>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 m-auto col-sm-12">
                <GatsbyImage
                  className="mb-5"
                  image={post?.mainImage?.asset?.gatsbyImageData}
                  alt={post.title}
                />
                {post.body ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(post.body),
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="blog-nav">
              <div className="row">
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto col-sm-12">
                  <div className="row">
                    <div className="col-md-6 text-md-left my-5">
                      {previous ? (
                        <div>
                          <span className="font-weight-bold text-uppercase text-muted d-block small">
                            Previous
                          </span>
                          <Link to={`/blog/${previous.slug.current}`}>
                            {previous.title}
                          </Link>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 text-md-right my-5">
                      {next ? (
                        <div>
                          <span className="font-weight-bold text-uppercase text-muted d-block small">
                            next
                          </span>
                          <Link to={`/blog/${next.slug.current}`}>
                            {next.title}
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        description
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, fit: FILL)
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      excerpt
    }
  }
`
